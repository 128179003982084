.contact{
    /* padding: 2em; */
}
.contact-con{
    border: 2px solid black; 
    padding: 20px; 
    width: 600px;
    margin: 0 auto;
    text-align: start;    
}
.sendBtn{
    display: flex;
    position: relative;
    margin: 1em;
    justify-content: center;
    align-items: center;
}
.footer-con{
    padding-top: 1em;
    width: 100%;
}
.radio{
    display: block;
    margin: 0 auto; 
}
@media screen and (max-width: 768px) {
    .contact-con{
        border: 2px solid black; 
        padding: 20px; 
        width: 300px;
        margin: 0 auto;
        text-align: start;    
    }
 }