/* Home.css */

.home-container {
    background-image: url('../../Images/cubicbackground.jpg');
    background-size: cover;
    background-position: center;
    transform: skewY(1deg);
    z-index: 2;
    height: 70vh;
    opacity: 95%;

  }
  .weUnder{
    position: relative;

  }
  .hometag{
    color: #FFFFFF;
    position: relative;
    align-items: center;
  }
  .hometag2{
    color: #FFFFFF;
    position: relative;
    align-items: center;
  }
  .homeText{
     padding-top: 10em;
     padding-bottom: 1em;
  }
  .homeBtn{
    background-color: #FFFF00 !important;
    color: black !important;
  }
  .css-sghohy-MuiButtonBase-root-MuiButton-root {
    background-color: #FFFF00 !important;
    color: black !important;
  }
  .card1{
    width: 3em;
  }
  .css-46bh2p-MuiCardContent-root {
    width: 80px !important;
  }
  .about-cards{
    padding: 1em;
    display: grid;
    grid-template-columns: repeat(2, 0fr); 
    gap: 2rem;
    position: relative;
    align-items: center;
    justify-content: center; 
    padding-bottom: 2em;
 
  }

  .banner-image2{
    padding-top: 2em;
    padding-bottom: 2em;
    width: 2000px;
    height: 300px;
  }
  .banner-image2{
    width: 63em;
  }
  .Carousel-div{ 
    padding-top: 2em;
  }
  .cara{
    position: relative;
    padding-top: 2em;
  }


@media screen and (max-width: 768px) {
  .home-container {
    background-image: url('../../Images/cubicbackground.jpg');
    background-size: cover;
    background-position: center;
    transform: skewY(1deg);
    z-index: 2;
    height: 35em;
    opacity: 95%;

  }
  .hometag{

  }
.banner-image{
  display: none;
}
.banner-image2{
  display: none;
}

.about-cards{
  /* padding: 1em; */
  display: block;
  grid-template-columns: repeat(2, 0fr); 
  gap: 2rem;
  position: relative;
  align-items: center;
  justify-content: center; 
  padding-bottom: 2em;

}
.homeBtn{
  transform: skewY(-1deg);
}
.About-container{
  padding-top: 1em;
}
}

@media screen and (max-width: 480px) {

}
