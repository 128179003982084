/* Banner.css */

.banner {
    background-color: #0D267F;
    color: #fff;
    padding: 20px;
    text-align: center;
    padding-bottom: 2em;
    transform: skewY(2deg);
  }
  
  .banner-content {
    max-width: 960px;
    margin: 0 auto;
    padding: 1em;
    transform: skewY(-2deg);
   
  }
  .aboutimg{
    border-radius: 50%;
    width: 500px;
  }
.bannerBtndiv{
  padding-top: 2em;
}
.bannerBtn{
  background-color: #FFFF00 !important;
  color: black !important;
}
.css-sghohy-MuiButtonBase-root-MuiButton-root {
  background-color: #FFFF00 !important;
  color: black !important;
}
  @media (max-width: 768px) {
    .banner {
      padding: 10px;
    }
    .aboutimg{
      border-radius: 50%;
      width: 300px;
    }
    .image-text{
      margin-left: 1em !important;
      display: none;
    }
    .aboutimg{
      display: none;
    }
  }
  .image-text{
    position: relative;
    font-family: Inter; 
    font-size: 20px !important;   
    font-style: italic;
    font-weight: 600;  
    line-height: 22px; 
    letter-spacing: 0em; 
    text-align: center;   
    margin-left: 5em !important;
  }