.serv-image3{
    width: 700px;
    height: 500px;
    display: block;
    margin: 0 auto; 
}
.Serv1-t{
    padding-top: 1em;
}
.training1{
    padding-top: 1em;
    padding-bottom: 1em;
}
.body1{
    padding-top: 1em;
    padding-bottom: 1em;   
}
.serv3-article{
  width: 45em;
  display: block;
  margin: 0 auto; 
}
.Service3{
    text-align: start;
}
.Serv3-typo{
    display: flex;
    justify-content: center;
    align-items: center !important;
    text-align: center;
  }
  .css-sghohy-MuiButtonBase-root-MuiButton-root {
    background-color: #FFFF00 !important;
    color: black !important;
  }
  @media (max-width: 768px) {
    .Serv3-typo{
      display: flex;
      justify-content: center;
      align-items: center !important;
      text-align: center;
    }
    .serv3-article{
      width: 15em;
      display: block;
      margin: 0 auto; 
    }
    .serv-image3{
      width: 15em;
      height: 15em;
    }
    .servBtn{
        padding: 2em;
        display: block;
        margin: 0 auto; 
        margin-left: 2em;
      }
  }