/* card3.css */

.card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 300px;
}

.card-image-container {
  position: relative;
}

.card-image {
  width: 100%;
  height: auto;
}

.card-title3 {
  position: absolute;
  top: 50%; /* Position it 50% down from the top */
  left: 50%; /* Position it 50% from the left */
  transform: translate(-50%, -50%); /* Move it back by 50% of its own width and height */
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 1.2rem;
}

.card-content {
  padding: 10px;
}
