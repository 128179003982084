.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: white;
  color: #333;
}

.logo{
  height: 100px;
}

.menu ul {
  list-style: none;
  display: flex;
  gap: 20px;
  text-decoration: none;
}

.menu ul li {
  position: relative;
  text-decoration: none;
}
.link{
  position: relative;
  text-decoration: none;
  color: black;
}

.menu ul li span {
  cursor: pointer;
}

.menu ul li .dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  padding: 10px;
}

.menu ul li:hover .dropdown-content {
  display: block;
}

.burger-menu {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 3px 0;
  transition: 0.4s;
}

.active .bar:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.active .bar:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -6px);
}

@media (max-width: 768px) {
  .burger-menu {
    display: flex;
    z-index: 2;
  }

  .menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 70px;
    right: 0;
    background-color: white;
    width: 100%;
    text-align: center;
    z-index: 1;
  }

  .menu.active {
    display: flex;
  }

  .menu.active a {
    color: #333;
  }

  .menu.active ul {
    flex-direction: column;
    gap: 10px;
  }

  .menu.active li {
    margin: 0;
  }
  .logo{
    height: 100px;
  }
}
