/* CardCarousel.css */

.App-Carousel{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44vh;
  
}
.carousel-root {
  max-width: 300px;
  width: 100%;
}